import { useTranslate } from '/machinery/I18n'
import { useInvokeCallback } from '/machinery/useGlobalCallback'
import { useGlobalPageState } from '/machinery/useGlobalPageState'

import { ContainerLg } from '/features/buildingBlocks/Container'

import styles from './CareerCoachCta.css'

import logoCareerCoach from '/images/logo-career-coach.png'

export function CareerCoachCtaSection({ title, introduction }) {
  return <CareerCoachCtaSectionBase {...{ title, introduction }} />
}

export function CareerCoachCtaSectionWithIllustration() {
  return ((
    <CareerCoachCtaSectionBase renderIllustration={({ className }) => (
      <img src='/images/illustrations/butterflies-and-rocks.png' alt='' className={cx(styles.illustration, className)} />
    )} />
  ))
}

function CareerCoachCtaSectionBase({ title = undefined, introduction = undefined, renderIllustration = undefined }) {
  const { __ } = useTranslate()

  return (
    <CareerCoachCtaBase
      renderContent={({ onClick }) => (
        <section data-x='career-coach-cta' data-style-context='white' className={styles.section}>
          <div className={styles.sectionContentWrapper}>
            <ContainerLg>
              <div className={styles.sectionContentContainer}>
                <TitleAndIntroductionLg
                  title={title ?? __`career-coach-cta`}
                  introduction={introduction ?? __`career-coach-cta-section-introduction`}
                  layoutClassName={styles.titleAndIntroductionLayout}
                />

                <CareerCoachCtaCard {...{ onClick }} />
              </div>
            </ContainerLg>
          </div>

          {renderIllustration && renderIllustration({ className: styles.illustrationLayout })}
        </section>
      )}
    />
  )
}

export function CareerCoachCtaList() {
  const { __ } = useTranslate()

  return (
    <CareerCoachCtaBase
      renderContent={({ onClick }) => (
        <li className={styles.listItem}>
          <TitleAndIntroductionMd
            title={__`career-coach-cta`}
            introduction={__`career-coach-cta-list-introduction`}
          />

          <Button
            label={__`start`}
            dataX='click-to-open-career-coach-cta'
            ariaLabel={__`open-career-coach`}
            layoutClassName={styles.buttonLayout}
            {...{ onClick }}
          />
        </li>
      )}
    />
  )
}

function CareerCoachCtaBase({ renderContent }) {
  const openCareerCoach = useInvokeCallback('openCareerCoach')
  const [, setInteractive] = useGlobalPageState('career-coach-is-interactive')

  return renderContent({ onClick: handleClick })

  function handleClick() {
    openCareerCoach()
    setInteractive(true)
  }
}

function CareerCoachCtaCard({ onClick }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentCard}>
      <div className={styles.cardContainer}>
        <div className={styles.cardTextContainer}>
          <Subtitle subtitle={__`career-coach-welcome`} layoutClassName={styles.subtitleLayout} />
          <img src={logoCareerCoach} alt='' className={styles.logo} />
        </div>

        <Button
          label={__`start`}
          dataX='click-to-open-career-coach-cta'
          ariaLabel={__`open-career-coach`}
          layoutClassName={styles.buttonLayout}
          {...{ onClick }}
        />
      </div>
    </div>
  )
}

function Subtitle({ subtitle, layoutClassName = undefined }) {
  return <i className={cx(styles.componentSubtitle, layoutClassName)}>{subtitle}</i>
}

function Button({ label, dataX, ariaLabel, onClick, layoutClassName }) {
  return (
    <button
      type='button'
      data-x={dataX}
      aria-label={ariaLabel}
      className={cx(styles.componentButton, layoutClassName)}
      {...{ onClick }}
    >
      <span className={styles.buttonBackground}>
        {label}
      </span>
    </button>
  )
}

function TitleAndIntroductionMd({ title, introduction }) {
  return <TitleAndIntroductionBase className={styles.componentTitleAndIntroductionMd} {...{ title, introduction }} />
}

function TitleAndIntroductionLg({ title, introduction, layoutClassName }) {
  return <TitleAndIntroductionBase className={cx(styles.componentTitleAndIntroductionLg, layoutClassName)} {...{ title, introduction }} />
}

function TitleAndIntroductionBase({ title, introduction, className }) {
  return (
    <div className={cx(styles.componentTitleAndIntroductionBase, className)}>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.introduction}>{introduction}</p>
    </div>
  )
}
