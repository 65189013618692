const callbackRegistry = new Map()

function registerCallback(key, callback) {
  callbackRegistry.set(key, callback)
}

export function useInvokeCallback(key) {
  return React.useCallback(
    (value) => {
      const callback = callbackRegistry.get(key)

      if (callback) callback(value)
    },
    [key]
  )
}

export function useGlobalCallback(key, callback) {
  React.useEffect(() => {
    registerCallback(key, callback)

    return () => { callbackRegistry.delete(key) }
  }, [key, callback])
}
